import React from "react";
import {
  Box,
  Typography,
  Avatar,
  IconButton,
  Button,
  Link,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ContactsIcon from "@mui/icons-material/Contacts";
import WebIcon from "@mui/icons-material/Web";

const MichinAndKellyLayout = ({
  businessCard,
  companyConfig,
  handleAddToContacts,
}) => {
  const { socialIcons } = companyConfig;
  const firstName = businessCard.name.split(" ")[0];

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // backgroundColor: companyConfig.backgroundColor || "#1f1f1f",
        backgroundColor: "#002240",
        color: companyConfig.textColor || "#FFFFFF",
        fontFamily: "'Montserrat', sans-serif",
      }}
    >
      {/* Top Section with Background Image */}
      <Box
        sx={{
          width: "100%",
          height: "350px",
          //   backgroundImage: `url(${companyConfig.backgroundImage})`,
          //   backgroundSize: "cover",
          //   backgroundPosition: "center",
          position: "relative",
          backgroundColor: "#002240",
        }}
      >
        {/* Company Logo */}
        <Box
          sx={{
            position: "absolute",
            top: "20%", // Adjusted to ensure it doesn't overlap with the avatar
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 2, // Ensure logo is above the overlay
            padding: "8px", // Optional: Add padding for the logo container
          }}
        >
          <img
            src={companyConfig.logo}
            alt={`${companyConfig.name} Logo`}
            style={{
              maxWidth: "150px", // Adjust the size of the logo
              height: "auto",
            }}
          />
        </Box>

        {/* Avatar */}
        <Avatar
          alt={businessCard.name}
          src={businessCard.profilePicURL}
          sx={{
            width: 250,
            height: 250,
            border: "3px solid #fff",
            position: "absolute",
            bottom: "-20px",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      </Box>

      {/* Bottom Section */}
      <Box
        sx={{
          width: "100%",
          //   backgroundColor: "#333",
          padding: "24px 16px",
          textAlign: "center",
          flexGrow: 1,
        }}
      >
        {/* Name */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            position: "relative", // Required for the ::after pseudo-element
            marginBottom: "12px",
            marginTop: "12px",
            fontSize: "1.8rem",
            textTransform: "uppercase",
            textAlign: "center", // Center-align text if needed
            "::after": {
              content: '""', // Empty content to create the underline
              position: "absolute",
              width: "60%", // Adjust underline width as needed
              height: "3px", // Height of the underline
              backgroundColor: "#993300", // Blue or company-specific color
              bottom: "-8px", // Space between text and underline
              left: "50%", // Center the underline
              transform: "translateX(-50%)", // Center alignment adjustment
            },
          }}
        >
          {businessCard.name}
        </Typography>

        {/* Position */}
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
            textTransform: "uppercase",
            color: "rgba(255, 255, 255, 0.8)",
            marginBottom: "10px",
            fontSize: "1rem",
          }}
        >
          {businessCard.position || "Professional"}
        </Typography>

        {/* Company Name */}
        {/* <Typography
          variant="h6"
          sx={{
            textTransform: "uppercase",
            fontWeight: 600,
            color: companyConfig.primaryColor || "#4CAF50",
            marginBottom: "24px",
            fontSize: "1.2rem",
          }}
        >
          {businessCard.company || "Company Name"}
        </Typography> */}

        {/* Contact Details */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "24px", // Increased spacing for a cleaner layout
            lineHeight: 1.8,
            gap: "12px", // Consistent spacing between items
          }}
        >
          {/* Phone */}
          <Link
            href={`tel:${businessCard.phone}`}
            underline="none"
            sx={{
              color: "#FFFFFF",
              fontSize: "1rem",
              display: "flex",
              alignItems: "center", // Ensures icon and text align vertically
              width: "100%",
              maxWidth: "300px",
            }}
          >
            <PhoneIcon
              sx={{ marginRight: "12px", flexShrink: 0, color: "#FFFFFF" }}
            />
            <Typography
              sx={{
                textAlign: "left", // Proper alignment for multiline text
                wordBreak: "break-word", // Handles long text gracefully
              }}
            >
              Call {firstName || "Not Available"}
            </Typography>
          </Link>

          {/* Email */}
          <Link
            href={`mailto:${businessCard.email}`}
            underline="none"
            sx={{
              color: "#FFFFFF",
              fontSize: "1rem",
              display: "flex",
              alignItems: "center",
              width: "100%",
              maxWidth: "300px",
            }}
          >
            <EmailIcon
              sx={{ marginRight: "12px", flexShrink: 0, color: "#FFFFFF" }}
            />
            <Typography
              sx={{
                textAlign: "left",
                wordBreak: "break-word",
              }}
            >
              Email {firstName || "Not Available"}
            </Typography>
          </Link>

          {/* Address */}
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              maxWidth: "300px",
            }}
          >
            <LocationOnIcon
              sx={{ marginRight: "12px", flexShrink: 0, color: "#FFFFFF" }}
            />
            <Typography
              sx={{
                textAlign: "left",
                wordBreak: "break-word",
              }}
            >
              {businessCard.address || "Address not provided"}
            </Typography>
          </Box> */}

          <Link
            href={"https://www.minchinkelly.bw/contact-us/"}
            target="_blank"
            underline="none"
            sx={{
              color: "#FFFFFF",
              fontSize: "1rem",
              display: "flex",
              alignItems: "center",
              width: "100%",
              maxWidth: "300px",
            }}
          >
            <LocationOnIcon
              sx={{ marginRight: "12px", flexShrink: 0, color: "#FFFFFF" }}
            />
            <Typography
              sx={{
                textAlign: "left",
                wordBreak: "break-word",
              }}
            >
              Address & Location
            </Typography>
          </Link>

          {/* Website */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              maxWidth: "300px",
            }}
          >
            <WebIcon
              sx={{ marginRight: "12px", flexShrink: 0, color: "#FFFFFF" }}
            />
            <Typography
              component="a"
              href={companyConfig.website || "#"}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: "#FFFFFF",
                textAlign: "left",
                wordBreak: "break-word",
                textDecoration: "none",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              {companyConfig.website || "Website not provided"}
            </Typography>
          </Box>
        </Box>

        {/* Social Media Icons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
            marginBottom: "10px",
          }}
        >
          {socialIcons?.linkedin && (
            <IconButton
              href={socialIcons.linkedin}
              target="_blank"
              sx={{ color: companyConfig.primaryColor }}
            >
              <LinkedInIcon />
            </IconButton>
          )}
          {socialIcons?.facebook && (
            <IconButton
              href={socialIcons.facebook}
              target="_blank"
              sx={{ color: companyConfig.primaryColor }}
            >
              <FacebookIcon />
            </IconButton>
          )}
          {socialIcons?.twitter && (
            <IconButton
              href={socialIcons.twitter}
              target="_blank"
              sx={{ color: companyConfig.primaryColor }}
            >
              <TwitterIcon />
            </IconButton>
          )}
          {socialIcons?.instagram && (
            <IconButton
              href={socialIcons.instagram}
              target="_blank"
              sx={{ color: companyConfig.primaryColor }}
            >
              <InstagramIcon />
            </IconButton>
          )}
        </Box>

        {/* Footer with Website */}
        {/* <Typography
          variant="body2"
          sx={{
            color: "rgba(255, 255, 255, 0.8)",
            fontSize: "0.9rem",
          }}
        >
          {businessCard.website || "www.yourwebsite.com"}
        </Typography> */}
        <Box sx={{ marginTop: "16px" }}>
          <Button
            variant="contained"
            startIcon={<ContactsIcon />}
            sx={{
              backgroundColor: companyConfig.primaryColor || "#4CAF50",
              ":hover": {
                backgroundColor: companyConfig.secondaryColor || "#388E3C",
              },
              color: companyConfig.buttonTextColor || "#FFFFFF",
              fontWeight: "bold",
              borderRadius: "20px",
            }}
            onClick={handleAddToContacts}
          >
            Add to Contacts
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MichinAndKellyLayout;
