import greenloopLogo from "../assets/greenloop-logo.png";
import sasaLogo from "../assets/sasa-logo.png";
import defaultLogo from "../assets/default-logo.png";
import sasaBackground from "../assets/sasabackground.png";
import mnklogo from "../assets/m&klogo.png";
import mnkbackground from "../assets/m&Kbackground.jpg";
// import m&kbackground from

const companies = {
  greenloop: {
    name: "Green Loop ESG Consultants",
    primaryColor: "#133d3f",
    secondaryColor: "#FFC107",
    backgroundColor: "#FFFFFF",
    paperBackground: "#FFFFFF",
    textColor: "#212121",
    secondaryTextColor: "#757575",
    fontFamily: "Arial, sans-serif",
    buttonTextColor: "#FFFFFF",
    buttonBorderRadius: "20px",
    logo: greenloopLogo, // Imported logo path
    emailSender: "businesscardsbotswana@gmail.com",
    website: "https://greenloop.co.bw/",
    layout: "default",
  },
  sasainteriors: {
    name: "Sasa Interiors",
    primaryColor: "#000000",
    layout: "elegant", // Layout style for Sasa
    secondaryColor: "#FFFFFF",
    backgroundColor: "#f8f8f8",
    paperBackground: "#ffffff",
    textColor: "#000000",
    secondaryTextColor: "#7e7e7e",
    fontFamily: "Century Gothic, sans-serif",
    buttonTextColor: "#000000",
    buttonBorderRadius: "12px",
    logo: sasaLogo, // Imported logo path
    emailSender: "businesscardsbotswana@gmail.com",
    website: "https://sasainteriors-bw.com/",
    // socialIcons: ["facebook", "twitter", "instagram", "linkedin", "youtube"], // Optional: List of supported social icons
    socialIcons: {
      facebook: "https://www.facebook.com/sasainteriorsandhomeware",
      linkedin: "https://www.linkedin.com/company/sasa-interiors-and-homeware/",
      instagram:
        "https://www.instagram.com/sasainteriorsandhomeware/?igsh=aWM5ZXgzemtteHJi",
    },
    backgroundImage: sasaBackground, // Imported background image
  },
  minchinkelly: {
    name: "Michin and Kelly",
    primaryColor: "#0076be", // Teal color for highlights
    secondaryColor: "#2C5B68", // Darker teal for hover effects
    backgroundColor: "#1C1C1C", // Dark background for the card
    paperBackground: "#333333", // Slightly lighter background for contrast
    textColor: "#FFFFFF", // White text for main headings
    secondaryTextColor: "rgba(255, 255, 255, 0.7)", // Muted white for details
    fontFamily: "Montserrat, sans-serif", // Clean and professional font
    buttonTextColor: "#FFFFFF", // White text for buttons
    buttonBorderRadius: "16px", // Rounded buttons to match the style
    logo: mnklogo, // Path to the Michin and Kelly logo
    emailSender: "contact@michinandkelly.com",
    website: "https://minchinkelly.bw/",
    layout: "michinAndkelly",
    backgroundImage: mnkbackground, // Path to the background image
    socialIcons: {
      facebook: "https://facebook.com/minchinkellybotswana",
      linkedin: "https://www.linkedin.com/company/minchin-kelly-botswana/",
    },
  },
  default: {
    name: "Default Company",
    primaryColor: "#4CAF50",
    secondaryColor: "#FFC107",
    backgroundColor: "#FFFFFF",
    paperBackground: "#FFFFFF",
    textColor: "#212121",
    secondaryTextColor: "#757575",
    fontFamily: "Arial, sans-serif",
    buttonTextColor: "#FFFFFF",
    buttonBorderRadius: "20px",
    // logo: defaultLogo, // Imported logo path
    emailSender: "businesscardsbotswana@gmail.com",
  },
};

export default companies;
