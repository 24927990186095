import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  Box,
  CircularProgress,
} from "@mui/material";
import { storage, db, functions } from "../../configs/firebaseConfig"; // Firebase services
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Firebase storage methods
import { collection, addDoc } from "firebase/firestore"; // Firestore methods
import { getAuth } from "firebase/auth";
import QRCode from "qrcode"; // Library for generating QR codes directly
import { httpsCallable } from "firebase/functions"; // For calling Firebase functions
import companies from "../../configs/companies"; // Local company configuration

const BusinessCardForm = ({ onAddCard, companyName }) => {
  const companyConfig = companies[companyName] || companies.default; // Fetch the current company's details

  const [formData, setFormData] = useState({
    name: "",
    position: "",
    bio: "",
    phone: "",
    email: "",
    address: "",
    profilePic: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle image upload
  const handleImageUpload = (e) => {
    setFormData({
      ...formData,
      profilePic: e.target.files[0],
    });
  };

  // Upload the QR code to Firebase Storage
  const uploadQRCode = async (qrCodeDataURL) => {
    const qrCodeBlob = await fetch(qrCodeDataURL).then((r) => r.blob());
    const storageRef = ref(storage, `qrCodes/${formData.email}-qrcode.png`);
    const snapshot = await uploadBytes(storageRef, qrCodeBlob);
    return await getDownloadURL(snapshot.ref); // Get the QR code download URL
  };

  // Form submission logic
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let profilePicURL = "";

      // Ensure the user is authenticated before uploading
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        throw new Error("You must be logged in to upload files.");
      }

      // Upload profile picture to Firebase Storage if a file was selected
      if (formData.profilePic) {
        const storageRef = ref(
          storage,
          `profilePics/${formData.profilePic.name}`
        );
        const snapshot = await uploadBytes(storageRef, formData.profilePic);
        profilePicURL = await getDownloadURL(snapshot.ref);
      }

      // Save business card data in Firestore and get the document reference
      const docRef = await addDoc(collection(db, "businessCards"), {
        name: formData.name,
        position: formData.position,
        bio: formData.bio,
        phone: formData.phone,
        email: formData.email,
        address: formData.address,
        profilePicURL,
        company: companyName, // Save the current company name
      });

      // Generate the public URL for the business card
      const generatedQrCodeURL = `https://businesscardsbw.web.app/${companyName}/business-card/${docRef.id}`;

      // Generate the QR code as a data URL
      const qrCodeDataURL = await QRCode.toDataURL(generatedQrCodeURL);

      // Upload the QR code image to Firebase Storage
      const qrCodeDownloadURL = await uploadQRCode(qrCodeDataURL);

      // Send email with the QR code via Firebase function
      const sendEmailWithQRCode = httpsCallable(
        functions,
        "sendEmailWithQRCode"
      );

      await sendEmailWithQRCode({
        email: formData.email,
        qrCodeDownloadURL,
        qrCodeURL: generatedQrCodeURL,
        name: formData.name,
        senderEmail: companyConfig.emailSender, // Use company-specific sender email
      });

      // Call the onAddCard callback to update the card list in the parent
      onAddCard({
        id: docRef.id,
        name: formData.name,
        position: formData.position,
        bio: formData.bio,
        phone: formData.phone,
        email: formData.email,
        address: formData.address,
        profilePicURL,
        qrCodeURL: generatedQrCodeURL,
      });

      // Reset form after successful submission
      alert("Business card added and email sent successfully!");
      setFormData({
        name: "",
        position: "",
        bio: "",
        phone: "",
        address: "",
        email: "",
        profilePic: null,
      });
      setError("");
    } catch (error) {
      console.error(
        "Error uploading file or saving business card:",
        error.message
      );
      setError(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Paper
        elevation={3}
        sx={{ padding: 4, backgroundColor: companyConfig.formBackground }}
      >
        <Typography variant="h5" gutterBottom>
          Add New Business Card
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                name="name"
                variant="outlined"
                fullWidth
                value={formData.name}
                onChange={handleChange}
                required
                InputLabelProps={{
                  style: { color: "black" }, // Black label text
                }}
                InputProps={{
                  style: { color: "black" }, // Black input text
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Position"
                name="position"
                variant="outlined"
                fullWidth
                value={formData.position}
                onChange={handleChange}
                required
                InputLabelProps={{
                  style: { color: "black" },
                }}
                InputProps={{
                  style: { color: "black" },
                }}
              />
            </Grid>

            {/* Conditionally render Bio field */}
            {companyName !== "michinandkelly" && (
              <Grid item xs={12}>
                <TextField
                  label="Bio"
                  name="bio"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={formData.bio}
                  onChange={handleChange}
                  required
                  InputLabelProps={{
                    style: { color: "black" },
                  }}
                  InputProps={{
                    style: { color: "black" },
                  }}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                label="Phone"
                name="phone"
                variant="outlined"
                fullWidth
                value={formData.phone}
                onChange={handleChange}
                required
                InputLabelProps={{
                  style: { color: "black" },
                }}
                InputProps={{
                  style: { color: "black" },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                name="email"
                variant="outlined"
                fullWidth
                value={formData.email}
                onChange={handleChange}
                required
                InputLabelProps={{
                  style: { color: "black" },
                }}
                InputProps={{
                  style: { color: "black" },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address"
                name="address"
                variant="outlined"
                fullWidth
                value={formData.address}
                onChange={handleChange}
                required
                InputLabelProps={{
                  style: { color: "black" },
                }}
                InputProps={{
                  style: { color: "black" },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                component="label"
                color="primary"
                fullWidth
              >
                Upload Profile Picture
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                fullWidth
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Submit"}
              </Button>
              {error && (
                <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                  {error}
                </Typography>
              )}
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};

export default BusinessCardForm;
